import React from 'react';

const Small = ({ children, className }) => {

    return (
        <span className={`text-sm lg:text-base leading-4 lg:leading-6 tracking-wide font-normal${` `}${className}`}>
            {children}
        </span>
    )
}

export default Small