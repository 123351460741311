import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Nav from '../components/sections/nav';
import Footer from '../components/sections/footer';
import Cookies from '../components/sections/cookies';

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
          }
        }
      `}
      render={(data) => (
        <div className='min-h-full flex flex-col content-center bg-white'>
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
            htmlAttributes={{ lang: 'pl' }}>
          </HelmetDatoCms>
          <div className="c_container flex flex-col lg:flex-row lg:space-x-md">
            <Nav></Nav>
            <main className='min-h-screen space-y-md lg:border-l-2 border-text border-opacity-5 lg:pl-sm'>{children}</main>
          </div>
          <Footer siteName={data.datoCmsSite.globalSeo.siteName}></Footer>
          <Cookies siteName={data.datoCmsSite.globalSeo.siteName}/>
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
