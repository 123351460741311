import React from 'react'
import { Link } from 'gatsby';
import Small from '../blocks/Small';
import CookieConsent from 'react-cookie-consent';

const Cookies = (props) => {

    return (
        <CookieConsent
            containerClasses='fixed flex flex-col md:flex-row md:flex-wrap justify-center items-center select-none w-auto bg-white z-50 font-bold px-sm lg:px:md py-xs lg:py-sm border-2 border-primary border-opacity-50 m-xs text-center'
            buttonClasses='button-main text-primary border-2 border-primary mt-xs md:mt-0 md:ml-sm'
            disableStyles='true'
            buttonText='Akceptuj i zamknij'
            cookieName='gatsby-gdpr-google-analytics'
        >
            <Small className="text-text">
                Strona {props.siteName}.eu korzysta z plików Cookies, aby zapewnić najlepszą jakość użytkowania.
                <Link
                    className='text-primary'
                    to='/polityka-prywatnosci'>
                    {' '}Dowiedz się więcej{' '}
                </Link>lub zamknij ten komunikat.
            </Small>
        </CookieConsent>
    )
}

export default Cookies
