import React from 'react'
import { Link } from 'gatsby';
import Small from '../blocks/Small';

const Footer = (props) => {

    return (
        <footer className='c_container text-text py-md space-y-sm'>
            <div className='flex justify-between'>
                <Small>Copyright © {new Date().getFullYear()},{' '}{props.siteName}</Small>
                <Link to='/polityka-prywatnosci'>
                    <Small>Polityka prywatności</Small>
                </Link>
            </div>
        </footer>
    )
}

export default Footer
