import React from 'react';
import { Link } from 'gatsby';
import Icon from '../graphics/icon';
import Logo from '../graphics/logo';
import Banner from "../../../static/placeholder.jpg"

const Nav = (props) => {
    
    const navLinks = [
        {
            id: 0,
            link: "/",
            name: "Home",
        },
        {
            id: 1,
            link: "/o-nas",
            name: "O nas",
        },
        {
            id: 2,
            link: "/links",
            name: "Linki",
        }
    ];

    const socialLinks = [
        {
            id: 0,
            name: "YouTube",
            link: "https://www.youtube.com/channel/UChgvaDV_QRVMHyZ7hQHfCvw",
            icon: "youtube",
        },
        {
            id: 1,
            name: "Facebook",
            link: "https://web.facebook.com/GoodLabelRecordings/",
            icon: "facebook",
        },
        {
            id: 2,
            name: "Instagram",
            link: "https://www.instagram.com/goodlabelrecordings/",
            icon: "instagram",
        },
        {
            id: 3,
            name: "BC Bandcamp",
            link: "https://madawwa.bandcamp.com/",
            icon: "bandcamp",
        },
        {
            id: 4,
            name: "Discogs",
            link: "https://www.discogs.com/label/1583847-Good-Label-Recordings?fbclid=IwAR3YbIabNlLgECm6akCDutrvpUuyHw00gCoHgLmMO46cXfx-BtO2_rBKuJQ",
            icon: "discogs",
        },
    ];

    return (
        <nav className="bg-white py-xxs lg:space-y-sm" name="top">
            <Link
                to="/"
                title="Home">
                <Logo className={"lg:hidden w-44 -ml-sm"} />
            </Link>
            <div className="flex flex-col space-y-xs lg:space-y-sm">
                {navLinks.map((item, id) => (
                    <Link
                        key={id}
                        to={item.link}
                        title={item.name}
                        className='font-mono text-lg tracking-wide text-primary hover:underline hover:text-primaryDark'>
                        {item.name}
                    </Link>
                ))}
            </div>
            <div className="flex justify-between md:justify-start md:space-x-xs py-sm">
                {socialLinks.map((item, id) => (
                    <a
                        key={id}
                        className="text-text hover:text-primaryDark"
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={item.name}>
                        <Icon
                            fill='none'
                            iconName={item.icon}
                            className='fill-current inline-block h-sm w-sm'>
                        </Icon>
                    </a>
                ))}
            </div>
            <a
                className="flex justify-center font-mono text-lg tracking-wide bg-primary text-white hover:bg-primaryDark px-xs py-xxs"
                href="https://winyle.com.pl/szukaj?controller=search&s=+good+label+recordings"
                target="_blank"
                rel="noopener noreferrer"
                title="Komis Płytowy Królestwo - Sklep Winyle">
                Sklep ➔
            </a>
        </nav>
    )
}

export default Nav
